import {Box, Grid, Typography} from '@material-ui/core'
import classNames from 'classnames'
import {Formik} from 'formik'
import PropTypes from 'prop-types'
import React, {ReactNode, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	CustomButtonComponent as CustomButton,
	CustomTextInputComponent as CustomTextInput,
	CustomSuccessComponent as CustomSuccess,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {CyclisLogoSvg} from '@svg/index'
import {forgotPassword} from '@templates/ForgotPassword/forgot-password-slice'
import {forgotPasswordSchema} from '@templates/ForgotPassword/forgot-password-validation'
import {LanguageContext} from '@utils/context'
import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * ForgotPassword
 */
interface ForgotPasswordProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

interface ForgotPasswordFormFields {
	username: string
}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)

	const forgotPasswordSuccess = useSelector(
		(state: RootState) => state.forgotPassword.success
	)
	const forgotPasswordLoading = useSelector(
		(state: RootState) => state.forgotPassword.loading
	)
	const userEmail = useSelector(
		(state: RootState) => state.forgotPassword.username
	)

	const initialValues = {username: ''}

	const submitForgotPassword = async (
		values: ForgotPasswordFormFields
	): Promise<void> => {
		dispatch(forgotPassword(values.username))
	}

	const ForgotPasswordForm: React.FC = () => (
		<Box className={classes.centerContainer}>
			<Box className={classNames(classes.titleBox)}>
				<Typography variant={'h2'} className={classes.title}>
					{t('ForgotPasswordTitle')}
				</Typography>
				<Grid item md={12} sm={12} xs={11}>
					<Typography className={classes.subTitle} variant={'body1'}>
						{t('ForgotPasswordSubTitle')}
					</Typography>
				</Grid>
			</Box>
			<Formik
				initialValues={initialValues}
				validationSchema={forgotPasswordSchema(t)}
				onSubmit={submitForgotPassword}
			>
				{({
					values,
					handleChange,
					handleBlur,
					handleSubmit,
					errors,
					touched,
				}): ReactNode => (
					<form onSubmit={handleSubmit}>
						<CustomTextInput
							label={t('LoginEmailLabel')}
							error={(errors.username && touched.username) as boolean}
							helperText={(touched.username && errors.username) as string}
							helperTextId={errors.username && 'username-helper-text'}
							propsToDelegate={{
								placeholder: t('LoginEmailDefaultValue'),
								name: 'username',
								type: 'email',
								value: values.username,
								onChange: handleChange,
								onBlur: handleBlur,
								error: (errors.username && touched.username) as boolean,
								fullWidth: true,
							}}
						/>
						<Grid container className={classes.submitButtonContainer}>
							<Grid item md={6} sm={12} xs={12}>
								<CustomButton
									id={'forgot-password-back-button'}
									className={classNames(
										classes.backButton,
										classes.formActionButton,
										classes.gutter
									)}
									text={t('ForgotPasswordBackButton')}
									type={'text'}
									propsToDelegate={{
										onClick: (): void => {
											dispatch(navigate(`/${activeLanguage}/login`))
										},
										fullWidth: true,
									}}
								/>
							</Grid>
							<Grid item md={6} sm={12} xs={12}>
								<CustomButton
									id={'forgot-password-send-email-button'}
									className={classNames(
										classes.formActionButton,
										classes.gutter,
										classes.submitButton
									)}
									text={t('ForgotPasswordSendEmailButton')}
									propsToDelegate={{
										type: 'submit',
										disabled: forgotPasswordLoading,
										fullWidth: true,
									}}
								/>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</Box>
	)

	return (
		<>
			{forgotPasswordSuccess ? (
				<CustomSuccess
					id={'forgot-password-success-container'}
					headerText={t('ForgotPasswordSuccessTitle')}
					subTitle={
						<Typography
							className={classNames(classes.subTitle, classes.centeredText)}
							variant={'body1'}
						>
							{t('ForgotPasswordSuccessSubtitlePart1')}
							&nbsp;<strong>{userEmail}</strong>&nbsp;
							{t('ForgotPasswordSuccessSubtitlePart2')}
						</Typography>
					}
					buttonAction={(): void => {
						dispatch(navigate(`/${activeLanguage}/login`))
					}}
				/>
			) : (
				<Box className={classes.mainContainer}>
					<CyclisLogoSvg color className={classes.logo} />
					<Grid className={classes.grid} spacing={6} container direction="row">
						<Grid item md={4} sm={2} xs={1} />
						<Grid item md={4} sm={8} xs={10}>
							<ForgotPasswordForm />
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	)
}

ForgotPassword.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
}

export default ForgotPassword
