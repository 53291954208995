import {TFunction} from 'i18next'
import * as Yup from 'yup'

import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

export const forgotPasswordSchema = (t: TFunction): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		username: Yup.string()
			.email(t(FormValidationErrorMessages.EMAIL))
			.max(
				99,
				(obj) =>
					`${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
			)
			.required(t(FormValidationErrorMessages.REQUIRED)),
	})
