import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside forgotPassword functional component
 */

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		height: '100vh',
		overflowX: 'hidden',
	},
	gutter: {
		width: 'calc(100% - 15px)',
		[theme.breakpoints.down('md')]: {
			width: 'calc(100%)',
		},
	},
	grid: {
		[theme.breakpoints.down('sm')]: {
			marginLeft: theme.spacing(-30 / 8),
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: theme.spacing(-37.5 / 8),
		},
	},
	centerContainer: {
		marginTop: theme.spacing(120 / 8),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(88 / 8),
		},
	},
	logo: {
		height: '40px',
		width: '114px',
		marginTop: '2.1875rem',
		marginLeft: '2.5rem',
		position: 'absolute',
		[theme.breakpoints.down('md')]: {
			marginLeft: '4.125rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '2rem',
			marginTop: '1.3125rem',
		},
	},
	title: {
		fontWeight: 'bold',
		fontSize: '1.5rem',
		marginBottom: '0.75rem',
	},
	titleBig: {
		fontSize: '2.25rem',
		marginTop: theme.spacing(31 / 8),
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem',
		},
	},
	centeredText: {
		textAlign: 'center',
	},
	subTitle: {
		color: '#7e7e80',
		fontSize: '1rem',
	},
	titleBox: {
		marginBottom: '2rem',
	},
	formActionBar: {
		marginTop: '3.3125rem',
	},
	formActionButton: {
		padding: theme.spacing(1.5, 0),
	},
	backButton: {
		color: '#08080d',
	},
	boldEmail: {
		fontWeight: 'bold',
		color: '#7e7e80',
	},
	successButtonContainer: {
		marginLeft: 7.5,
		[theme.breakpoints.down('md')]: {
			marginLeft: 0,
		},
	},
	submitButtonContainer: {
		marginTop: theme.spacing(5.25),
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing(-15 / 8),
		},
	},
	submitButton: {
		marginLeft: 15,
	},
	mailIcon: {
		height: theme.spacing(174 / 8),
		width: theme.spacing(180 / 8),
		marginLeft: '7%',
		[theme.breakpoints.down('md')]: {
			marginLeft: '15%',
		},
	},
}))

export default useStyles
